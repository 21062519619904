import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import { Index } from "@/pages/index";
import LinhaProdutos  from "@/pages/Produtos";
import InstitucionalPage  from "@/pages/Institucional";
import ContatoPage  from "@/pages/Contato";

const Routes = () => {

  return (
    <BrowserRouter basename="/site">
      <Switch>
        <Route exact path="/" component={Index} />
        <Route exact path="/Produtos" component={LinhaProdutos} />
        <Route exact path="/Institucional" component={InstitucionalPage} />
        <Route exact path="/Contato" component={ContatoPage} />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
