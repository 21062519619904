/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Header from "@/components/headerOut";
import Footer from '@/components/footer'
import "primereact/resources/primereact.min.css"; 
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primeicons/primeicons.css"; 
import { InstitucionalCss } from "./styles";
import Instit from '@/assets/images/BannerInstitucional.jpg'
import Fachada from '@/assets/images/fotoSticle.jpg'

const InstitucionalPage = () => {
  document.title = "Sticle | Malharia desde 1945 | Institucional";  

  return (
    <InstitucionalCss>
      <Header/>         

        <div id="institucional">
          <div id="ancoraInstitucional"></div>
          <div id="Banner">
            <img src={Instit} alt="Fundada em 1945" className="img" />
          </div>

          <div id="infos">
            <p>
              <span>1945</span> 
              <div>Uma empresa focada inicialmente na produção de tecidos planos para camisaria. Começou a ganhar destaque por sua qualidade e versatilidade, obtendo cada vez mais espaço na área têxtil. 
              <br/>
              Por ser uma empresa inovadora e estar em constante atualização tecnológica, seu crescimento foi marcado por fornecer ao mercado tecidos de alta qualidade.
              </div>
            </p>
          
            <p>
              <span> 1988 </span>
              <div>Em sua terceira geração familiar, agora com cunho maior voltado para malhas de algodão. Começa de forma cautelosa adquirindo sua primeira máquina circular. Daí para frente não parou mais de crescer e se aperfeiçoar.</div>
            </p>

            <p>
              <span>1990</span>
              <div>Muda sua sede para Ribeirão Pires, numa área de 20 mil metros quadrados, com um parque fabril de última geração e uma equipe altamente qualificada, garantindo aos seus clientes produto de qualidade e inovador.</div>
            </p>

            <p>
              <span>2024</span>
              <div>A Sticle se reinventa mais uma vez e apresenta ao mercado sua nova e moderna identidade visual.</div>
            </p>              
            
          </div>

          <div id="fachada">
            <img src={Fachada} alt="Sticle" className="img" />
          </div>

        </div>        
      
      <Footer /> 
    </InstitucionalCss>
  );
  
}

export default InstitucionalPage;
