import styled from "styled-components";

export const LoadingCSS = styled.div`
  
  display:none;
  width: 100%;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.7);/*padrao*/
  
  height: 1000px;
  z-index: 10000;
  top: 0;

  #load{
     position: fixed;
     z-index: 1;
     top: 45%;
     left: 47%
  }

  img {
    margin: 0 auto;
    display: table;
    top: 25%;
    max-width:25%;
    position: relative;
  }

  .cortina{ 
    opacity:1;
    z-index:151;
       
		width: 100%;		
		height: 100%;
		position: relative;
  }

.circle {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  box-sizing: border-box;
  border: solid 5px rgba(255, 255, 255, 0.7);
  border-top-color: #FFF;
  animation: spin 1s infinite linear;
}

.circleloader {
  border-radius: 16px;
  width: 80px;
  height: 20px;
  background-color: rgba(255, 255, 255, 0.8);
}

  @keyframes spin { 
    100% { 
      transform: rotate(360deg); 
    } 
  } 


  @keyframes load {
      0% {opacity :0;}			
      100% {opacity : 1;}
  }
          
  @-webkit-keyframes load {
      0% {opacity :0;}			
      100% {opacity : 1;}	
  }


  @media only screen and (max-width: 1368px) {
    img {
      margin: 0 auto;
      display: table;
      top: 30%;
      position: relative;
    }
  }
  @media only screen and (max-width: 800px) {
    img {
      margin: 0 auto;
      display: table;
      top: 20%;
      position: relative;
    }
  }
  @media only screen and (max-width: 480px) {
      img {
        top:25%;
        max-width:80%;
      }
  }
`;
