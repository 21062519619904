import styled from "styled-components";

export const FooterCss = styled.div`

  
        position: relative;
        width: 100%;
        padding: 0;
        background-color: #000000;
        color: #fff;
        float: left;
    

    #logo{
        float: left;
        max-width: 260px;
        width: 90%;
    }

    #faleConosco{
        position: relative;

        section{
            float: left;
            width: 50%;
            font-size: 13px;
            padding: 3% 1%;
            font-weight: 300;
        }

        h3{
            font-size: 30px;
            font-weight: 500;
        }

        p{
            padding:1% 0
        }

        strong{
            font-weight: 500;
        }

    }

    #mapa{
        float: left;
        width: 100%;
    }


    .fav{
        padding: 0 20px;
        float: left;
        width: 60px;
    }

    #logoFooter{
        text-align: right;
    }

    #logoFooter img{
        max-width: 200px;
        height: auto;
    }

    #direitos{
        float: left;
        width: 100%;
        text-align: center;
        font-size: 13px;
        color:#333;
        padding: 1%;
        background: #fff;
    }

    #whats{
        width: 40px;
        height: 40px;
        position: fixed;
        bottom: 20px;
        right: 20px;
    }


    @media (max-width: 700px) {

        #faleConosco section{
            width: 100%;
            text-align: center;
        }

        .fav{
            display: inline-block;
            float: none
        }

         #faleConosco {             
            padding: 1% 5%;
        }

    }


`;