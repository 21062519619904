import styled from "styled-components";

import Produtos from "../../assets/images/fundoProduto.jpg";
import Blue from "../../assets/images/blue.jpg";

export const ProdutosCss = styled.div`


.produto{
        float: left;
        width: 100%;
        padding: 10px;
        border-bottom: 1px #ddd solid;
        font-size: 20px;
        margin: 1% 0;
        color: #717171;
        max-width: 1366px;

        .escudo{
            width:15%;
            float: left;
        }

        h3{
            display: block;
            border-bottom: 1px #afcff4 solid;
            padding: 5px 0;
            font-size: 25px;
            margin-bottom: 20px;
            color: #333;
        }

        p{
            float: left;
            width: 65%;
            padding: 0 20px;
        }

        .capa{
            float: right;
            width: 20%;
        }
    }


    #linhasProdutos{
        width: 100%;
        min-height: 150px;
        background: url(${Produtos});
        background-size: cover; /* Ajuste a imagem para cobrir a div */
        background-repeat: no-repeat; /* Evite a repetição da imagem */
        background-position: top center;
        position: relative;
        text-align: center;
        padding: 2% 15%;
        margin-bottom: 1%;
    }

    #prod{
        display: flex; /* Ativa o Flexbox */
        justify-content: space-around; /* Adiciona espaçamento igual entre os elementos */
        align-items: center; /* Opcional: Centraliza verticalmente */

            section{
                padding: 0;              
                color: #fff;
                text-align: center; 
                display: inline-flex; 
                
                img{
                    width: 150px
                }
                
            }
    }

    #linhasProdutos h3{
        color: #fff;
        font-size: 25px;
        margin: 5px 0 25px 0;
        font-weight: 500;
    }

    @media only screen and  (max-width: 820px){ 
        #linhasProdutos{
            padding: 2%;
        }

         #prod section img {
            width: 120px;
        }

    }
    

    @media only screen and  (max-width: 600px){    

      .produto p {
          float: left;
          width: 80%;
          padding: 0 15px;
      }

      #prod {
        flex-wrap: wrap; /* Permite que os elementos quebrem para a próxima linha */     
        section{    margin: 5px; padding: 10px 20px}

      }

      .capa{
        display: none
      }

  } 


`;

