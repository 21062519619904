import React from "react";
import { LoadingCSS } from "./styles";

export default function Loading(){  
  return (
    <LoadingCSS id="LoadingPage" className="cortina">
        <div id="load">
          <div className="circle"></div>
      </div>
    </LoadingCSS>
  );
}

