/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import Header from "@/components/headerOut";
import Footer from '@/components/footer'
import "primereact/resources/primereact.min.css"; 
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primeicons/primeicons.css"; 
import { ContatoCss } from "./styles";
import emailjs from '@emailjs/browser';
import Swal from 'sweetalert2';
import { visibleLoad } from "../../services/functions";
import InputMask from 'react-input-mask';
import Loading from '@/components/Loading';

const ContatoPage = () => {
  document.title = "Sticle | Malharia desde 1945 | Contato";  

    //contato
    const [block, SetBlock] = useState("");
    const [nome, SetNome] = useState("");
    const [empresa, SetEmpresa] = useState("");
    const [cargoEmpresa, SetCargoEmpresa] = useState("");
    const [telefone, SetTelefone] = useState("");
    const [email, SetEmail] = useState("");
    const [tipoProduto, SetTipoProduto] = useState("Linha Algodão");
    const [mensagem, SetMensagem] = useState("");

    useEffect(() => {

      if(nome === "" || empresa === "" || cargoEmpresa === "" || telefone === "" || email === "" || mensagem === ""){
        SetBlock("disabled")
      }else{
        SetBlock("")
      }
      
   }, [nome, empresa, cargoEmpresa, telefone, email, mensagem]);

    const EnviaContato= async () =>{   
      visibleLoad("LoadingPage")
  
      const dados = {
        "from_name": nome,
        "empresa" :empresa,
        "cargo" : cargoEmpresa,
        "telefone" : telefone,
        "email" : email,
        "produto" : tipoProduto,
        "mensagem" : mensagem
      }
      const response = await emailjs.send("service_14arnh4", "template_qdt0xh7", dados, "j4SAU4aWcsp8CeTnS")
  
      if(response.status === 200){
        visibleLoad("LoadingPage")
        Swal.fire({
          icon: 'success',
          title: 'Tudo certo!',
          html: 'Email enviado com sucesso! Em breve vamos entrar em contato!'      
        });
  
        SetNome("")
        SetEmpresa("")
        SetCargoEmpresa("")
        SetTelefone("")
        SetEmail("")
        SetMensagem("")
      }else{
        visibleLoad("LoadingPage")
        Swal.fire({
          icon: 'warning',
          title: 'Atenção!',
          html: 'Não conseguimos enviar o email!'      
        });
      }  
   
    };
  

  return (
    <ContatoCss>
      <Header/>        
      <Loading id="LoadingPage"/>
       
        <div id="Contato">
          <div id="fale"></div>
            <h3>Preencha o formulário que entraremos em contato</h3>

            <form>             
              <div className="Grid50">             
                <input 
                  type="text" 
                  value={nome} 
                  onChange={(e) => SetNome(e.target.value)} 
                  placeholder="* Nome"/>
              </div>

              <div className="Grid50">             
                <input 
                type="text" 
                value={empresa} 
                onChange={(e) => SetEmpresa(e.target.value)} 
                placeholder="* Empresa"/>
              </div>

              <div className="Grid30">             
                <input 
                type="text" 
                value={cargoEmpresa} 
                onChange={(e) => SetCargoEmpresa(e.target.value)} 
                placeholder="* Cargo na Empresa"/>
              </div>

              <div className="Grid30">             
                <InputMask  
                mask={"(99)99999-9999"}
                maskChar=""  
                type="text" 
                value={telefone} 
                onChange={(e) => SetTelefone(e.target.value)} 
                placeholder="* Telefone"/>
              </div>        

              <div className="Grid40">             
                <input 
                type="text" 
                value={email} 
                onChange={(e) => SetEmail(e.target.value)} 
                placeholder="* Email"/>
              </div>

              <div className="Grid100">             
                <textarea                            
                  rows="5" 
                  placeholder="* Mensagem"
                  value={mensagem} 
                  onChange={(e) => SetMensagem(e.target.value)}>
                </textarea>
              </div>

              <div className="Grid100">  
                <button type="button" onClick={() => EnviaContato()} className="button button-primary" disabled={block} style={{float: 'right', width: '150px'}}> Enviar</button>
              </div>
            </form>
        </div>      
      
      <Footer /> 
    </ContatoCss>
  );
  
}

export default ContatoPage;
