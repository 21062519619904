import React from "react";
import { BrowserRouter } from "react-router-dom";
import { GlobalStyle }  from "./styles/global";
import Routes from "./routes/routes";


import "primereact/resources/primereact.min.css"; 
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primeicons/primeicons.css"; 

const App = () => {
  return (
    <>
      <BrowserRouter>
          <GlobalStyle />
          <Routes />
      </BrowserRouter>
    </>
  )
};

export default App;



