/* eslint-disable jsx-a11y/anchor-is-valid */
import Logo from '@/assets/images/sticleHeader.png'
import { HeaderCss } from './style';

export default function Header(){
   /*  const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
      
        if (section) {
          section.scrollIntoView({
            behavior: "smooth",
          });
        }
      }; */
      
    return(
        <HeaderCss>
            <header id="header">
                <div className='full'>
                    <div id="logo">
                        <img 
                            src={Logo}
                            alt="Sticle" 
                            className='img'                           
                        />
                    </div>  
                    <nav id="menu">
                        <ul>
                            <li><a href="/#Home">Home</a></li>
                            <li><a href="/Institucional">Institucional</a></li>
                            <li><a href="/Produtos">Produtos</a></li>
                            <li><a href="/Contato">Contato</a></li>
                        </ul>
                    </nav>
                    <div className='clear'></div>
                </div>
            </header>
        </HeaderCss>
    )

}