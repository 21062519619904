/* eslint-disable jsx-a11y/anchor-is-valid */
import Logo from '@/assets/images/sticleHeader.png'
import { HeaderCss } from './style';
import Call from '@/assets/images/callcenter.png'
export default function Header(){
    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
      
        if (section) {
          section.scrollIntoView({
            behavior: "smooth",
          });
        }
      };
      
    return(
        <HeaderCss>
            <header id="header">
                <div className='full'>
                    <div id="logo">
                        <img 
                            src={Logo}
                            alt="Sticle"
                            className='img'                            
                        />
                    </div>  
                    <nav id="menu">
                        <ul>
                            <li><a onClick={() => scrollToSection('Home')}>Home</a></li>
                            <li><a href="/Institucional">Institucional</a></li>
                            <li><a href="/Produtos">Produtos</a></li>
                            <li><a href="/Contato">Contato</a></li>
                        </ul>
                    </nav>
                    <div className='clear'></div>
                </div>

                <div id="call">
                    <a href="https://lp.sticle.com.br/5off#rd-section-jsc3dnf3" target='_blank' rel="noreferrer">
                        <span>Falar com especialista</span>
                        <img 
                            src={Call}
                            alt="Sticle"
                            className='img' 
                            title='Falar com especialista'                           
                        />
                    </a>
                </div>
            </header>
        </HeaderCss>
    )

}