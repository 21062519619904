/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react/jsx-no-target-blank */
import Logo from '@/assets/images/sticleFooter.png'
import Whatsapp from "@/assets/images/whats.png";

import { FaInstagram , FaWhatsapp} from "react-icons/fa";
import { FooterCss } from './style';

export default function Footer(){

    return(
        <FooterCss>
            <footer id="footer">
                <div className='full'>

                    <div id="faleConosco">

                        <section>
                            <h3>Fale conosco:</h3>
                            <p>
                                <strong>Fone:</strong> (11) 96937-7891 - (11) 4827-0110 <br/>
                                <strong>E-mail: </strong> sticle@sticle.com.br <br/>
                            </p>                        

                            <p>
                                Av. Vereador Rubens Maziero, 957 - CEP: 09443-530 <br/>
                                Ouro fino Paulista – Ribeirão Pires – SP - Brasil
                            </p>
                            <p>
                                <a href="https://www.instagram.com/explore/tags/sticlemalharia/" target='_blank'  className="fav">
                                    <FaInstagram size={45} color="white"/>
                                </a>
                                <a href="https://wa.me/5511969377891?text=Ol%C3%A1,%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20a%20STICLE!" target='_blank'  className="fav">
                                    <FaWhatsapp size={45} color="white"/>
                                </a>
                            </p>
                        </section>

                        <section id="logoFooter">
                            <img 
                                src={Logo}
                                alt="Sticle"
                            />
                        </section>                       
                    </div>                                 
                   
                    <div className='clear'></div>
                </div>

                <div id="mapa">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3654.012206510623!2d-46.34568002386532!3d-23.675521465797694!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce72f55250a1b5%3A0x4f392a511dd2e57e!2sAv.%20Ver.%20Rubens%20Maziero%2C%20957%20-%20Ouro%20Fino%20Paulista%2C%20Ribeir%C3%A3o%20Pires%20-%20SP%2C%2008633-805!5e0!3m2!1spt-BR!2sbr!4v1700140099737!5m2!1spt-BR!2sbr" width="100%" height="350" style={{Border: "0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>  
            </footer>
            <div id="direitos">
                2023 - Todos os direitos reservados.
            </div>

            <a href="https://wa.me/5511969377891?text=Ol%C3%A1,%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20a%20STICLE!" target="_blank" rel="noreferrer">
                <img src={Whatsapp} alt="Whatsapp" title="WhatsApp" id="whats"/> 
            </a>
        </FooterCss>
    )

}