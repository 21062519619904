/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Container } from "./styles";




import Header from "@/components/header";
import Footer from '@/components/footer'
import Banner from '@/components/banner'
import BannerInstitucional from '@/assets/banner/institucional.jpg'
import BannerInstitucionalMB from '@/assets/banner/institucionalMB.jpg'
import BannerFundacao from '@/assets/banner/fundacao.jpg'
import BannerFundacaoMB from '@/assets/banner/fundacaoMB.jpg'
import BannerRespeito from '@/assets/banner/respeito.jpg'
import BannerRespeitoMB from '@/assets/banner/respeitoMB.jpg'
import BannerQualidade from '@/assets/banner/qualidade.jpg'
import BannerQualidadeMB from '@/assets/banner/qualidadeMB.jpg'

import maquina from '@/assets/images/maquinario2.jpg'
import IconAlgodao from '@/assets/images/icon-algodao.png'
import IconEco from '@/assets/images/icon-ecologica.png'
import IconElastano from '@/assets/images/icon-elastano.png'
import IconDiferenciado from '@/assets/images/icon-diferenciado.png'
import ReactHtmlParser from 'html-react-parser';

import "primereact/resources/primereact.min.css"; 
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primeicons/primeicons.css"; 
import { Dialog } from 'primereact/dialog';

import Produtos from "./produtos.json";
//let larguraTela = window.innerWidth;

const Index = () => {
  document.title = "Sticle | Malharia desde 1945";  
  
  const [modalContato, SetModalContato] = useState(false); 
  const [modalProdutos, SetModalProdutos] = useState(false); 
  const [listaProdutos, SetListaProdutos] = useState([]); 
  const [dadosProduto, SetDadosProduto] = useState();

 const banners = [
    {
      image: BannerInstitucional,
      imageMB: BannerInstitucionalMB,
      link: '#institucional'
    },
    {
      image: BannerFundacao,
      imageMB: BannerFundacaoMB,
      link: '#institucional'
    },
    {
      image: BannerRespeito,
      imageMB: BannerRespeitoMB,
      link: '#institucional'
    },
    {
      image: BannerQualidade,
      imageMB: BannerQualidadeMB,
      link: '#institucional'
    }
  ];

   useEffect(() => {
     SetListaProdutos(Produtos.resultado) 
  }, []);

  const toggleProdutos = async (id) =>{
    SetModalProdutos(!modalProdutos)

    if(id !== undefined){
      const produto = listaProdutos.find((p) => p.id === id);
      console.log("🚀 ~ file: index.js:70 ~ toggleProdutos ~ produto:", produto)
      SetDadosProduto(produto)
      
    }else{
      SetDadosProduto()
    }
  };


  return (
    <Container>
      <Header/>  
     
      <main className="main">

        <div id="banner">
        <div id="Home"></div>
          <Banner 
            lista={banners}
            infiniteLoop={true}
            stopOnHover={true}
            autoPlay={true}             
          />        
        </div>  

        <div id="compromisso">       
          <section>
            <div className="txtCompromisso">
                <h2>Compromisso e qualidade passados de geração em geração.</h2>
                <p>
                  Desde 1945 buscando oferecer soluções para as necessidades dos seus clientes. Entregando qualidade , inovação e versatilidade. <br/><br/>
                  Hoje na sua quarta geração tem como legado transformar para seus clientes o simples em extraordinário. 
                </p>
            </div>   
              <div id="video">
                <h4>Confira nosso vídeo</h4> 
                <iframe 
                  width="100%" 
                  height="100%" 
                  src="https://www.youtube.com/embed/fha27c4v8_s?si=qkmlt5bvK72n-2KF" 
                  title="Vídeo Sticle" 
                  frameborder="0" 
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                  allowfullscreen></iframe>
              </div> 
          </section>         

          <section id="fotoCompromisso">
            <img className="img" alt="Maquinário STLICE" src={maquina}/>
          </section>
        
        </div>       

        <div id="linhas">
          <div id="produtos"></div>
            <section className="blue">
              <div className="icones">
                <div className="icons iAlgodao" >
                  <img src={IconAlgodao} alt="Linha 100% Algodão" /> <br/>
                  Linha <br/> 100% Algodão
                </div>
                <div className="icons iEco">
                  <img src={IconEco} alt="Linha Ecológica" /> <br/>
                  Linha <br/> Ecológica
                </div>
                <div className="icons iElastano">
                  <img src={IconElastano} alt="Linha com Elastano" /> <br/>
                  Linha <br/> com Elastano
                </div>
                <div className="icons iDiferenciado">
                  <img src={IconDiferenciado} alt="Linha 100% Algodão" /> <br/>
                  Linha <br/> dos Diferenciados
                </div>
              </div>
            </section>

          <section>
            <div className="txtLinhas">
                <h3>Conheça nossas linhas</h3>
                <p>
                  Nossos produtos são desenvolvidos com a mais alta qualidade e tecnologia.
                </p>
                <p>
                  Possuímos artigos capazes de transformar o simples em extraordinário.
                </p>
                <p>                 
                  <a href="/Produtos">SAIBA MAIS</a>
                </p>                
            </div>      
          </section>
        </div>

        <div id="linhasProdutos">
          <h3>Transformando o simples em extraordinário</h3>
          <div id="prod">
            <section>MEIA MALHA</section>
            <section>SUEDINE</section>
            <section>PIQUET</section>
            <section>MOLETON</section>
            <section>RIBANAS</section>
          </div>
        </div>
      </main>  
   
      <Dialog header="Linha de Produtos" visible={modalProdutos} onHide={() => toggleProdutos(!modalProdutos)}
        style={{ width: '50vw' }} breakpoints={{ '960px': '75vw', '641px': '100vw' }}>
          <p className="m-0">

              <div id="linhasProdutos">
                <h3>Transformando o simples em extraordinário</h3>
                <div id="prod">
                    <section>MEIA MALHA</section>
                    <section>SUEDINE</section>
                    <section>PIQUET</section>
                    <section>MOLETON</section>
                    <section>RIBANAS</section>
                  </div>
              </div>               

              <div className="produto">
                <img className="img" alt={dadosProduto?.titulo} src={dadosProduto?.imagem}/>
                <p>
                    <h3>{dadosProduto?.Titulo}</h3>
                    {dadosProduto?.descricao !== undefined && ReactHtmlParser(dadosProduto?.descricao)}
                </p>
              </div>
          </p>
      </Dialog> 
      
      <Footer /> 
    </Container>
  );
  
}

export default Index;
