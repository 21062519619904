import styled from "styled-components";

import Produtos from "../../assets/images/fundoProduto.jpg";
import Blue from "../../assets/images/blue.jpg";

export const Container = styled.div`

#Home{
  position: absolute;
  top: -50px;
  left: 0;
}

#banner{
  float: left;
  width: 100%;
  background-color: #000;
  position: relative;
}

 #produtos, #ancoraInstitucional{
  position: absolute;
  top:-60px;
  left: 0;
}

#compromisso, #linhas, #linhasProdutos{
  float: left;
  width: 100%;
  padding: 1% 0;
  position: relative;
}

#compromisso{
  max-width: 1366px;
}

#compromisso section, #linhas section{
  float: left;
  width: 50%;
  position: relative;
}

#video{
  float: left;
  width: 100%;
  text-align: center;
  padding: 2%;

  iframe{
    width: 100%;
    height: 375px;
  }

    h4{
      font-size: 20px;
      width: 100%;
      float: left;
      font-size: 40px;
      font-weight: 500;
      color: #173580;
      padding: 10px;
      text-align: center;
    }
}

.txtCompromisso{
  float: right;
  max-width: 700px;
  width: 100%;
  color: #717171;
  padding: 1% 3%;
  font-size: 20px;
  font-weight: 300;

  h2{
    font-size: 42px;
    font-weight: 500;
    color: #173580;
    padding: 10px 0 30px 0;
    line-height: 40px;
  }
}

#linhasProdutos{
  width: 100%;
  min-height: 250px;
  background: url(${Produtos});
  background-size: cover; /* Ajuste a imagem para cobrir a div */
  background-repeat: no-repeat; /* Evite a repetição da imagem */
  background-position: top center;
  position: relative;
  text-align: center;
  padding: 1% 15%;
  margin-bottom: 1%;
}

#prod{
  display: flex; /* Ativa o Flexbox */
  justify-content: space-around; /* Adiciona espaçamento igual entre os elementos */
  align-items: center; /* Opcional: Centraliza verticalmente */

  section{
    padding: 10px 40px;
    border: 2px #fff solid;
    border-radius: 30px 0 30px 0;
    color: #fff;
    text-align: center; 
    display: inline-flex;
  }
}

#linhasProdutos h3{
  color: #fff;
  font-size: 30px;
  margin: 20px 0 40px 0;
  font-weight: 500;
}

.carousel-status{
  display: none
}

.blue{
  width: 100%;
  float: left;
  min-height: 340px;
  background: url(${Blue}); 
  background-size: cover; /* Ajuste a imagem para cobrir a div */
  background-repeat: no-repeat; /* Evite a repetição da imagem */
  position: relative;
}

.icones{
  position: absolute;
  max-width: 600px;
  width: 100%;
  top: 0;
  right: 0;
  padding: 10px
}

.icons{
    float: left;
    width: 50%;
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    color: #fff;
    padding: 15px;
    
}

.iAlgodao{
  border-right: 1px #b2cff1 solid;
  border-bottom: 1px #b2cff1 solid;
}

.iEco{
  border-bottom: 1px #b2cff1 solid;
}

.iElastano{
  border-right: 1px #b2cff1 solid;
}

.txtLinhas{
  float: left;
  max-width: 700px;
  width: 100%;
  color: #717171;
  padding: 6% 3%;
  font-size: 18px;
  font-weight: 300;
}

.txtLinhas h3{
  font-size: 40px;
  font-weight: 500;
  color: #173580;
  padding: 10px 0
}

.txtLinhas a{
  background-color: #173580;
  color: #fff;
  border-radius: 30px;
  padding: 10px 30px;
  font-weight: 500;
  text-decoration: none;
  float: left;
  margin-top: 20px;
  transition: 0.5s;
  border: none;
}

.txtLinhas a:hover{
  transition: 0.5s;
  background-color: #6ea9ef;
}


.btnVideo{
  background-color: #173580;
  color: #fff;
  border-radius: 30px;
  padding: 10px 30px;
  font-weight: 500;
  text-decoration: none;
  float: left;
  margin-top: 20px;
  transition: 0.5s;
  border: none;
  cursor: pointer;
}



@media only screen and  (max-width: 1440px){
   #video {
   

   iframe {        
        height: 415px;
    }
  }
  .txtCompromisso {
    font-size: 16px;
    h2{
      font-size: 35px;
    padding: 10px 0 20px 0;
    }
   
  }

  #Contato{
    padding: 1%;
  }
}

@media only screen and  (max-width: 820px){

    #compromisso section{
       width: 100%;
    }

    .txtCompromisso{
      max-width: 900px
    }

    .icons{
      font-size: 18px;
    }

    .txtLinhas{
      padding: 2% 5%;
    }

}

@media only screen and  (max-width: 480px){

  #video iframe {
      width: 100%;
      height: 250px;
  }

  #video {
    padding: 0;
      h4{
      font-size: 30px;
      padding: 10px;
      text-align: center;
    }
  }

  #produtos{
    top:-90px;
  }

  .blue{
    min-height: 310px;
  }

  .icons {
    font-size: 14px;
  }

  .txtLinhas button, .btnVideo{
    width: 100%
  }

  .txtCompromisso h2{
      font-size: 30px;
      line-height: 38px;
    }

    #linhas section{
      width: 100%;
    }

    .txtLinhas{
      text-align: center;
    }

    .txtLinhas a{
      width: 100%;
      padding: 15px 30px;
    }

    .txtLinhas h3{
      font-size: 30px;
      font-weight: 600;
    }

    #prod {
      flex-wrap: wrap; /* Permite que os elementos quebrem para a próxima linha */
     
      section{    margin: 5px }

    }


}


`;

export const Institucional = styled.div`


  #institucional{
    float: left;
    
    padding: 1% 0;
    max-width: 1366px;
    width: 100%;
  }

  #Banner{
    float: left;
    width: 100%;
  }

  #infos{
    float: left;
    width: 70%;
    font-size:18px;
    padding: 2%;
    color: #717171;
    
    span{      
      background-color: #5b92e4;
      padding: 5px; 
      color: #fff;
      width: 120px;
      text-align: center;
      border-radius: 20px 0 20px 0;
      float: left;
      margin: 0 20px 0 0;
      font-size: 25px;
    }

    div{
      float:left;
      width: 80%;
    }

    p{
      padding: 10px 5px;
      margin: 10px 0;
      float: left;
    }
  }

  #fachada{
    float: left;
    width: 30%;
  }


  @media only screen and  (max-width: 820px){
    #infos{
      width: 100%;

      div {
          float: left;
          width: 100%;
      }
    }

    #fachada{
      display: none
    }
  }
`;