import styled from "styled-components";

export const InstitucionalCss = styled.div`

  #institucional{
    margin: 0 auto;   
    padding: 4% 0;
    max-width: 1366px;
    width: 100%;
  }

  #Banner{
    float: left;
    width: 100%;
  }

  #infos{
    float: left;
    width: 70%;
    font-size:18px;
    padding: 2%;
    color: #717171;
    
    span{      
      background-color: #5b92e4;
      padding: 5px; 
      color: #fff;
      width: 120px;
      text-align: center;
      border-radius: 20px 0 20px 0;
      float: left;
      margin: 0 20px 0 0;
      font-size: 25px;
    }

    div{
      float:left;
      width: 80%;
    }

    p{
      padding: 10px 5px;
      margin: 10px 0;
      float: left;
    }
  }

  #fachada{
    float: left;
    width: 30%;
  }


  @media only screen and  (max-width: 820px){
    #infos{
      width: 100%;

      div {
          float: left;
          width: 100%;
      }
    }

     #Banner {       
        margin: 5% 0 0 0;
    }

    #fachada{
      display: none
    }
  }

  @media only screen and  (max-width: 820px){
    #Banner {
        margin: 17% 0 0 0;
    }
  }
`;