
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

export default function Banner({
    lista,
    infiniteLoop,
    stopOnHover,
    autoPlay
}){

    const handleItemClick = (index) => {
        const clickedBanner = lista[index];
        window.location.href = clickedBanner.link; 
    };

    return(
        <>
             <Carousel infiniteLoop={infiniteLoop} stopOnHover={stopOnHover} autoPlay={autoPlay} onClickItem={handleItemClick} showThumbs={false}>
                {
                    lista.map((banner, index) => (
                        <div key={index}>
                            <a href={banner.link}>
                                <img src={banner.image} alt="Institucional" className="pc img"/>
                                <img src={banner.imageMB} alt="Institucional" className="mb img"/>
                            </a>
                        </div>
                    ))
                }  
          </Carousel>
        </>
    )
}