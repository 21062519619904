/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { ProdutosCss } from "./styles";
import Header from "@/components/headerOut";
import Footer from '@/components/footer'
import ReactHtmlParser from 'html-react-parser';
import "primereact/resources/primereact.min.css"; 
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primeicons/primeicons.css"; 
import { Dialog } from 'primereact/dialog';
import ProdutosLinhas from "./produtos.json";

import TagRibana from '@/assets/images/tag-ribanas.png'
import TagMeiaMalha from '@/assets/images/tag-meia-malha.png'
import TagSuedine from '@/assets/images/tag-suedine.png'
import TagPiquet from '@/assets/images/tag-piquet.png'
import TagMoleton from '@/assets/images/tag-moleton.png'


let larguraTela = window.innerWidth;
console.log("🚀 ~ file: index.js:28 ~ larguraTela:", larguraTela)

const LinhaProdutos = () => {
  document.title = "Sticle | Malharia desde 1945 | Linha de Produtos";  
  
  const [modalProdutos, SetModalProdutos] = useState(false); 
  const [listaProdutos, SetListaProdutos] = useState([]); 
  const [dadosProduto, SetDadosProduto] = useState();

  useEffect(() => {
     SetListaProdutos(ProdutosLinhas.resultado) 
  }, []);

  const toggleProdutos = async (id) =>{
    SetModalProdutos(!modalProdutos)

    if(id !== undefined){
      const produto = listaProdutos.find((p) => p.id === id);    
      SetDadosProduto(produto)
      
    }else{
      SetDadosProduto()
    }
  };

  return (
    <ProdutosCss>
      <Header/>  
       
      <main className="main"> 
        
        <div id="linhasProdutos">
          <h3>Transformando o simples em extraordinário</h3>
          <div id="prod">
            <section><img src={TagMeiaMalha} alt="Meia Malha" className="img" title="Meia Malha"/></section>
            <section><img src={TagSuedine} alt="Suedine" className="img" title="Suedine"/></section>
            <section><img src={TagPiquet} alt="Piquet" className="img" title="Piquet"/></section>
            <section><img src={TagMoleton} alt="Moleton" className="img" title="Moleton"/></section>
            <section><img src={TagRibana} alt="Ribana" className="img" title="Ribanas"/></section>
          </div>
        </div>

        {                                                                     
          listaProdutos.map((prod, i) => { 

            return( 
              <div className="produto">
                <img className="escudo" alt={prod?.titulo} src={prod?.imagem}/>
                  <p>
                      <h3>{prod?.Titulo}</h3>
                      {prod?.descricao !== undefined && ReactHtmlParser(prod?.descricao)}
                  </p>
                  <img className="capa" alt={prod?.titulo} src={prod?.capa}/>
              </div>
              ) 
          })                            
        }  
        
      

      </main>  

    
      <Dialog header="Linha de Produtos" visible={modalProdutos} onHide={() => toggleProdutos(!modalProdutos)}
        style={{ width: '50vw' }} breakpoints={{ '960px': '75vw', '641px': '100vw' }}>
          <p className="m-0">

              <div id="linhasProdutos">
                <h3>Transformando o simples em extraordinário</h3>
                <div id="prod">
                    <section>MEIA MALHA</section>
                    <section>SUEDINE</section>
                    <section>PIQUET</section>
                    <section>MOLETON</section>
                    <section>RIBANAS</section>
                  </div>
              </div>               

          </p>
      </Dialog> 
      
      <Footer /> 
    </ProdutosCss>
  );
  
}

export default LinhaProdutos;
