import styled from "styled-components";

export const ContatoCss = styled.div`

#Contato{
  float: left;
  width: 100%;
  padding: 6% 15%;
  position: relative;

  #fale{
    position: absolute;
    top: -100px
  }

  h3{
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    color: #173580;
    padding: 10px 0
  }
}

@media only screen and  (max-width: 1440px){  
  #Contato{
    padding: 1%;
  }
}

  @media only screen and  (max-width: 820px){
    #infos{
      width: 100%;

      div {
          float: left;
          width: 100%;
      }
    }

    #fachada{
      display: none
    }

    #Contato {       
        padding: 13% 5% 5% 5%;
    }

  }
  @media only screen and  (max-width: 480px){

      #Contato {       
        padding: 25% 5% 5% 5%;
    }

  }


`;