import { createGlobalStyle } from "styled-components";
import Produtos from "../assets/images/fundoProduto.jpg";
export const GlobalStyle = createGlobalStyle`

    :root{
        --orange: #ffb400;
        --orangehover: #f66602;

        --pink: #f2bc1b;
        --grey: #333333; 
        --grey2: #767676;
        --dark: #000000;
        --text-body: #666666;
        --text-title: #5E48E8;
    }

    *{
        margin: 0;
        box-sizing: border-box
    }

    a{
        text-decoration: none
    }

    *:focus {
        outline: none;
    }

    html{
        scroll-behavior: smooth;
        margin: 0;
        padding: 0;
    }


    body{
        font-family: 'Kanit', sans-serif;
    }

    input, textarea, button{
        font-family: 'Kanit', sans-serif;
    }


    h1, h2, h3, h4, h5, h6, strong{
        font-weight: 600;
    }

    ::-webkit-input-placeholder { /* Edge */
    color: var(--grey);
    opacity: 0.8;
    font-family: 'Kanit', sans-serif;
    }


    .img{
        width: 100%;
        height: auto
    }

    .full{
        max-width: 1366px;
        margin: 0 auto;
    }

    .main {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 75px 0 0 0 ;
        min-height: 100vh;
    }


    .mb{
        display: none!important;
    }

    .Grid10, .Grid15, .Grid20, .Grid25, .Grid30, .Grid40, .Grid50, .Grid60, .Grid70, .Grid80, .Grid90, .Grid100{
		position: relative;		
		float: left;
		padding: 5px;	
		text-transform: initial;

        label{
			display: block;
			padding: 5px 0;
			font-size: 14px;
			text-transform: initial;
            font-family: 'Kanit', sans-serif;
		}
    }

    .Grid100{
		width: 100%;
	}

	.Grid90{
		width: 90%;
	}

	.Grid70{
		width: 70%;
	}

	.Grid60{
		width: 60%;
	}

	.Grid50{
		width: 50%;
	}

	.Grid40{
		width: 40%;
	}

	.Grid30{
		width: 30%;
	}

	.Grid25{
		width: 25%;
	}

	.Grid20{
		width: 20%;
	}
	.Grid15{
		width: 15%;
	}

	.Grid10{
		width: 10%;
	}

        
  

    .p-dialog .p-dialog-content {
        background: #ffffff;
        color: #495057;
        padding: 0 1rem 1rem 1rem;
    }

    .p-dialog .p-dialog-header{
        padding: 1rem;
    }

    input[type="text"],
	input[type="password"],
	input[type="File"],
	input[type="date"],
	select
	{	
		float: left;
		width:100%;
		background:#fff;
		border:1px #ddd solid;
		padding:10px;
		color: #5f5f5f;
		font-size: 15px;
		font-family: 'Maven Pro', sans-serif;
		transition: 0.5s;
        border-radius: 2px;
        height: 50px;

		:hover{
			transition: 0.5s;
			background: #f7f7f7;
			border:1px #cbcbcb solid;
		}

		:focus {
			transition: 0.5s;
			border: 1px solid #000;
		}

		.large{
			padding: 20px;
		}

	}	

    textarea{	
		float: left;
		width:100%;
		background:#fff;
		border:1px #ddd solid;
		padding:10px;
		color: #5f5f5f;
		font-size: 15px;
		font-family: 'Maven Pro', sans-serif;
		transition: 0.5s;
        border-radius: 2px;

		:hover{
			transition: 0.5s;
			background: #f7f7f7;
			border:1px #cbcbcb solid;
		}

		:focus {
			transition: 0.5s;
			border: 1px solid #000;
		}

		.large{
			padding: 20px;
		}

	}	



    input[type="button"],
	input[type="submit"],
	button {			
		font-size: 14px;
		text-transform: uppercase;
		font-weight: 600;
		cursor: pointer;
		display: inline-block;		
        font-family: 'Kanit', sans-serif;
		transition: 0.5s;
		font-size: 12px;
        padding: 10px
	}

    .button{
		border-radius: 50px;
		text-align: center;
		width: 100%;
		cursor: pointer;
		font-family: 'Kanit', sans-serif;
		font-weight: 600;

		:disabled {
			opacity: 0.7;
			cursor: not-allowed!important;
			background: #cbcbcb!important;
			color: #666!important;
			border: 1px #cbcbcb solid!important;	

		}

	}

    .button-primary{
		color: #fff;
		background: #0079ba;
		border: 1px #0079ba solid;
		transition: 0.5s;

		:hover{
			background: #045e8e;
			border: 1px #000 solid;
			transition: 0.5s;
		}

		:active {
            background: #000;
			color: #fff;          
        }
	}	

@media only screen and (max-width: 1024px) {
    
}



@media only screen and  (max-width: 820px){

    .main{
        min-height: 100%
    }

    .Grid10, .Grid15, .Grid20, .Grid25, .Grid30, .Grid40, .Grid50, .Grid60, .Grid70, .Grid80, .Grid90, .Grid100{
        width: 100%;
    }
}


@media only screen and  (max-width: 600px){
    .pc{
        display: none!important;
    }

    .mb{
        display: inline-block!important;
    }

    .main{
        padding: 75px 0 0 0;
    }

    .produto p {
        float: left;
        width: 80%;
        padding: 0 15px;
    }

    #prod {
      flex-wrap: wrap; /* Permite que os elementos quebrem para a próxima linha */     
      section{    margin: 5px; padding: 10px 20px}

    }

} 






`