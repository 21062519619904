import styled from "styled-components";

export const HeaderCss = styled.div`

#header{
    position: fixed;
    width: 100%;
    padding: 5px;
    background-color: #71a9eb;
    z-index: 10;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    height: 75px;
}

#logo{
    float: left;
    width: 20%;
}

#menu{
    position: absolute;
    bottom: 30px;
    left: 0;   
    width: 100%;
    margin-top: 20px;
    text-align: center;
    
    ul{
        list-style: none;       
        /* justify-content: space-between; */
        padding: 0; 
        text-align: center    
    }

    li{
        margin: 0;
        display: inline-block;

        a{
            color: #fff;
            font-weight: 200;
            transition: 0.5s;
            cursor: pointer;
            padding: 0 20px;

            :hover{
                color: #fff;
                font-weight: 500;
                transition: 0.5s;
            }

        }
    }
}
@media (max-width: 820px) {

    #logo {
        width: 20%;
        margin: 17px 0 0 0;
    }
}

@media (max-width: 480px) {

    #menu{
        bottom: 5px;

        li a{
            padding: 0 15px;
            font-size: 14px;
        }
    }

    #logo{ 
        width:100%;
        text-align: center;
        margin: 0;

        img{
            max-width: 180px
        }
    }

}

@media (max-width: 380px) {
    #menu{
        li a{
            padding: 0 7px;
        }
    }
}
`;